import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"
import CertificateISO from "../../static/images/certificados/applus_iso.svg"

const ISO = () => {
  const data = useStaticQuery(graphql`
    query imagesISO {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `) 
  
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Nueva versión ISO 27001:2022 | Qualoom</title>
        <meta name="title" content="Nueva versión ISO 27001:2022 | Qualoom"/>
        <meta name="description" content="Aprende sobre la nueva ISO 27001:2022. Implementa un SGSI sólido, protege tu información y cumple con requisitos legales. Guía completa y práctica."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/normativa-iso-27001/"/>
        <link rel="canonical" href="https://www.qualoom.es/normativa-iso-27001/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/normativa-iso-27001/"/>
        <meta property="og:title" content="Nueva versión ISO 27001:2022 | Qualoom"/>
        <meta property="og:description" content="Aprende sobre la nueva ISO 27001:2022. Implementa un SGSI sólido, protege tu información y cumple con requisitos legales. Guía completa y práctica."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/1axoUA9qs9GkQbj7fwZ8NF/b6948e72a5fe30417185211204ac04dd/twitter_card_iso.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/normativa-iso-27001/"/>
        <meta property="twitter:title" content="Nueva versión ISO 27001:2022 | Qualoom"/>
        <meta property="twitter:description" content="Aprende sobre la nueva ISO 27001:2022. Implementa un SGSI sólido, protege tu información y cumple con requisitos legales. Guía completa y práctica."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/1axoUA9qs9GkQbj7fwZ8NF/b6948e72a5fe30417185211204ac04dd/twitter_card_iso.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/normativa-iso-27001/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Normativa ISO 27001:2022", "item": {"@id": "https://www.qualoom.es/normativa-iso-27001/", "name": "Normativa ISO 27001:2022"}},
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-servicios">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1> Normativa y cumplimiento</h1>
              <p>Nuestra empresa ha incorporado la última normativa ISO 27001:2022, asegurando la confidencialidad y la integridad de nuestros datos en el mundo digital y fortaleciendo la seguridad de la información.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="services-main">
        <div className="services-main-content">
          <h2>La nueva versión de la norma internacional para SGSI</h2><hr></hr>
          <div className="section-main">
            <div className="section-img iso-bg"><a href="/documents/certificates/Qualoom Expertise Technology - SGSI Certificado ISO270012022 Seguridad de la informacion.pdf" target="_blank"><img src={CertificateISO} alt="Certificación Seguridad de la Información - ISO 27001:2022"></img></a></div>
            <div className="section-text">
              <p>La norma ISO 27001:2022 es una norma internacional que establece los requisitos para un Sistema de Gestión de Seguridad de la Información (SGSI). En un mundo cada vez más digitalizado, proteger la información sensible se ha vuelto fundamental para las organizaciones. Qualoom, como empresa líder en su industria, ha obtenido la certificación ISO 27001, lo que garantiza que cumple con los más altos estándares de seguridad de la información.</p>
              <p>La norma ISO 27001 fue actualizada en 2022 para adaptarse a los cambios tecnológicos y a las nuevas amenazas que enfrentan las organizaciones en la era digital. Esta última versión ofrece una visión más completa y actualizada de los requisitos para establecer, implementar, mantener y mejorar continuamente la seguridad de la información dentro de Qualoom.</p>
            </div>
          </div><br/>
          <h3>Objetivos de la norma ISO 27001</h3>
          <p>El principal objetivo de ISO 27001 es establecer un marco de trabajo sólido que ayude a las organizaciones a proteger su información de manera efectiva. Al obtener la certificación ISO 27001, Qualoom garantiza que cumple con los siguientes objetivos clave de la norma:</p>
          <ul>
            <li>Proteger la confidencialidad, integridad y disponibilidad de la información</li>
            <li>Identificar y evaluar los riesgos de seguridad de la información</li>
            <li>Implementar controles de seguridad adecuados para tratar los riesgos identificados</li>
            <li>Establecer un sistema de gestión de seguridad de la información eficaz y coherente</li>
            <li>Proporcionar confianza a los clientes, socios comerciales y otras partes interesadas sobre la gestión de la seguridad de la información</li>
          </ul>
        </div>
      </div>
      <div className="services-main gray iso">
        <div className="services-main-content">
          <h2>Estructura de la norma ISO 27001</h2><hr></hr>
          <p>La norma ISO 27001, con la certificación obtenida por Qualoom, sigue una estructura basada en cláusulas y anexos que asegura una gestión integral de la seguridad de la información. Algunas de las principales cláusulas de la norma, aplicadas por Qualoom, son las siguientes:</p>
          <ol type="1">
            <li><b>Contexto de la organización:</b> Qualoom establece el alcance del SGSI y considera el contexto interno y externo de la organización para adaptar sus medidas de seguridad de manera adecuada.</li>
            <li><b>Liderazgo:</b> La alta dirección de Qualoom muestra su compromiso y liderazgo en relación con la seguridad de la información, garantizando una cultura de seguridad desde arriba.</li>
            <li><b>Planificación del SGSI:</b> Qualoom planifica el sistema de gestión de seguridad de la información y evalúa los riesgos para tomar medidas preventivas y correctivas adecuadas.</li>
            <li><b>Soporte:</b> Qualoom proporciona los recursos necesarios, asegura la competencia del personal, facilita la comunicación interna y documenta el SGSI de manera adecuada.</li>
            <li><b>Operación del SGSI:</b> Qualoom implementa controles operacionales y gestiona sus activos de información, abordando amenazas y vulnerabilidades de manera efectiva.</li>
            <li><b>Evaluación del desempeño:</b> Qualoom realiza auditorías internas y revisiones de gestión periódicas para evaluar el cumplimiento de los controles y el desempeño general del SGSI.</li>
            <li><b>Mejora continua:</b> Qualoom adopta acciones correctivas y preventivas para mantener y mejorar continuamente su SGSI, ajustándose a las nuevas circunstancias.</li>
          </ol><br/>
          <h3>Beneficios de implementar ISO 27001</h3>
          <p>La implementación de un Sistema de Gestión de Seguridad de la Información basado en ISO 27001 y certificado por Qualoom proporciona numerosos beneficios para la empresa y sus clientes y proveedores, entre ellos:</p>
          <ul>
            <li><b>Mejora de la confianza de los clientes:</b> La certificación ISO 27001 otorgada a Qualoom asegura a sus clientes que la información estará protegida de manera adecuada y confiable.</li>
            <li><b>Cumplimiento de requisitos legales:</b> Qualoom cumple con las leyes y regulaciones relacionadas con la seguridad de la información, brindando una mayor tranquilidad a todas las partes interesadas.</li>
            <li><b>Gestión eficaz de riesgos:</b> La norma ISO 27001 proporciona a Qualoom un marco para identificar, evaluar y tratar los riesgos de seguridad de la información de manera sistemática y eficiente.</li>
            <li><b>Mejora de la resiliencia:</b> La implementación de controles de seguridad adecuados ayuda a Qualoom a resistir y recuperarse de incidentes de seguridad, minimizando su impacto.</li>
            <li><b>Ventaja competitiva:</b> La certificación ISO 27001 otorga a Qualoom una ventaja competitiva al demostrar su compromiso y enfoque en la seguridad de la información, lo que le distingue en el mercado.</li>
          </ul>
        </div>
      </div>
      <div className="services-main iso">
        <div className="services-main-content">
          <h2>Proceso de implementación de ISO 27001</h2><hr></hr>
          <p>La implementación de ISO 27001 en Qualoom sigue un proceso general que consta de los siguientes pasos:</p>
          <ol type="1">
            <li><b>Establecimiento del alcance:</b> Qualoom define el alcance de su SGSI y determina qué activos de información se incluirán en el sistema.</li>
            <li><b>Identificación de activos de información:</b> Qualoom identifica los activos de información y evalúa su valor y criticidad para priorizar las medidas de seguridad adecuadas.</li>
            <li><b>Evaluación de riesgos:</b> Qualoom realiza una evaluación de riesgos para identificar y analizar las amenazas y vulnerabilidades que pueden afectar a sus activos de información.</li>
            <li><b>Selección de controles de seguridad:</b> Qualoom selecciona los controles de seguridad adecuados para tratar los riesgos identificados y asegurar la protección de la información.</li>
            <li><b>Implementación de controles:</b> Qualoom implementa los controles de seguridad seleccionados y establece procedimientos y políticas para su gestión y mantenimiento.</li>
            <li><b>Auditoría interna:</b> Qualoom lleva a cabo auditorías internas periódicas para evaluar el cumplimiento de los controles y la eficacia del SGSI en su funcionamiento.</li>
            <li><b>Revisión de gestión:</b> Qualoom realiza revisiones de gestión periódicas para evaluar el desempeño general del SGSI y tomar acciones correctivas y preventivas según sea necesario.</li>
            <li><b>Certificación:</b> Qualoom solicita una auditoría de certificación a un organismo de certificación acreditado para obtener la certificación ISO 27001 y demostrar su conformidad con la norma.</li>
          </ol><br/>
          <h3>Mantenimiento y mejora continua</h3>
          <p>Qualoom entiende que el mantenimiento y la mejora continua del SGSI son fundamentales para mantenerse a la vanguardia de la seguridad de la información. Algunas prácticas importantes que Qualoom implementa incluyen:</p>
          <ul>
            <li><b>Realizar auditorías regulares:</b> Qualoom realiza auditorías internas y externas de manera periódica para evaluar el cumplimiento de los controles y las prácticas de seguridad de la información.</li>
            <li><b>Realizar revisiones de gestión:</b> Qualoom efectúa revisiones de gestión con frecuencia para evaluar el desempeño del SGSI y determinar oportunidades de mejora.</li>
            <li><b>Actualización de controles:</b> Qualoom actualiza y mejora los controles de seguridad según surjan nuevas amenazas o se implementen nuevas tecnologías para mantener la eficacia del SGSI.</li>
            <li><b>Promover la conciencia de seguridad:</b> Qualoom capacita y concientiza al personal sobre las políticas y procedimientos de seguridad de la información para fomentar una cultura de seguridad sólida.</li>
            <li><b>Participar en la comunidad de seguridad:</b> Qualoom se mantiene actualizado sobre las mejores prácticas y participa en la comunidad de seguridad para intercambiar conocimientos y experiencias que fortalezcan su SGSI.</li>
          </ul>
        </div>
      </div>
      <Footer />
      </div>
  )
}

export default ISO
